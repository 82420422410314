<template>
    <div v-if="item != null">
        <!-- Info trazabilidad -->
        <v-row no-gutters>
            <v-col cols="2">
                <v-alert
                    colored-border border="left"
                    color="blue"
                    elevation="3" tile
                >
                    <div>Código</div>
                    <h3>{{item.Art.ArtCod}}</h3>
                </v-alert>
            </v-col>
            <v-col cols="5">
                <v-alert
                    colored-border border="left"
                    color="blue"
                    elevation="3" tile
                >
                    <div>Artículo</div>
                    <h3>{{item.Art.ArtNom}}</h3>
                </v-alert>
            </v-col>
            <v-col cols="2">
                <v-alert
                    colored-border border="left"
                    color="blue"
                    elevation="3" tile
                >
                    <div>Total</div>
                    <h3>{{item.ArtTrazCantTotal}}</h3>
                </v-alert>
            </v-col>
            <v-col cols="3">
                <v-alert
                    colored-border border="left"
                    color="blue"
                    elevation="3" tile
                >
                    <div>Periodo</div>
                    <h3>{{filtroFecha}}</h3>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-divider></v-divider>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="dataRows"
            :options.sync="options"
            :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100], disablePagination:loading, disableItemsPerPage: loading }"
            :server-items-length="totalDataRows"
            :loading="loading"
            style="overflow: scroll"
            mobile-breakpoint="0"
            class="elevation-1 mt-8"
            dense>
        </v-data-table>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default ({
    components: {},
    computed:{
        ...mapState(['empId', 'urlRaiz']),
        filtroFecha: function() {
            let desde = '~';
            if (this.filtros.desdeFecha != null && this.filtros.desdeFecha !== '')
            {
                desde = this.formatDate(this.filtros.desdeFecha);
            }
            let hasta = '~';
            if (this.filtros.hastaFecha != null && this.filtros.hastaFecha !== '')
            {
                hasta = this.formatDate(this.filtros.hastaFecha);
            }

            if (desde === '~' && hasta === '~') { return 'Sin especificar'; }
            return desde  + ' - ' +  hasta;
        }
    },
    props: {
      item: { default: null },
      filtros: { default: {} }
    },
    watch: {
        item() {
            if(this.item == null)
            {
                this.clearData();
                return;
            }
            this.loadData();
        },
        'options.page': {
            handler () {
                if(this.item == null) { return; }
                this.loadData();
            },
            deep: true
        },
        'options.itemsPerPage': {
            handler () {
                if(this.item == null) { return; }
                this.loadData();
            },
            deep: true
        }
    },
    data () {
        return {
            // Tabla
            loading: false,
            totalDataRows: 0,
            dataRows: [],
            options: {page: 1,itemsPerPage: 15},
            headers: [
                { text: 'FECHA', value: 'ArtTrazFecha', width: "100", sortable: false },
                { text: 'HORA', value: 'ArtTrazHora', width: "100", sortable: false },
                { text: 'CANTIDAD', value: 'ArtTrazCant', width: "100", sortable: false },
                { text: 'LOTE', value: 'ArtTrazLote', width: "120", sortable: false },
                { text: 'CADUCIDAD', value: 'ArtTrazCadFecha', width: "180", sortable: false },
                { text: 'RETIRADA', value: 'FechaRetirada', width: "180", sortable: false }
            ]
        }
    },
    methods: {
        getDataFromApi() {
            this.loading = true;

            var NumRegsPag = this.options.itemsPerPage;
            var NumPag = this.options.page;

            return new Promise((resolve) => {
                const controllerParameters = {
                    Action: 'GET_ART_LIST_PAGINATED',
                    ArtId: this.item.Art.ArtId,
                    desdeFecha: this.filtros.desdeFecha !== '' ? this.filtros.desdeFecha : null,
                    hastaFecha: this.filtros.hastaFecha !== '' ? this.filtros.hastaFecha : null,
                    desdeCaducidad: this.filtros.desdeCaducidad !== '' ? this.filtros.desdeCaducidad : null,
                    hastaCaducidad: this.filtros.hastaCaducidad !== '' ? this.filtros.hastaCaducidad : null,
                    DirId: this.filtros.dir != null ? this.filtros.dir.DirId : null,
                    NumRegsPag: NumRegsPag,
                    NumPag: NumPag,
                    EmpId: this.empId,
                    ArtTrazLote: this.filtros.lote,
                    TieneCantidad: true,
                };
                const AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/ArtTraz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result => {
                    const items = result.data.EntsList;
                    const total = result.data.EntsTotCount;

                    setTimeout(() => {
                        this.loading = false
                        resolve({
                            items,
                            total
                        })
                    }, 1000)
                })
                .catch(error => {
                    if(error != null) {
                        this.loading = false;
                        alert('Error al cargar el detalle de trazabilidad.');
                    }
                });
            })
        },
        loadData() {
            if (this.loading) { return; }
            if (this.item == null) { return; }

            this.getDataFromApi()
            .then(data => {
                this.dataRows = data.items;
                this.totalDataRows = data.total;
            });
        },
        clearData() {
            this.dataRows = [];
            this.totalDataRows = 0;
            if (this.options.page !== 1) { this.options.page = 1; }
        },
        formatDate (value) {
            const date = new Date(value);
            return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
        }
    }
})
</script>

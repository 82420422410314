<template>
  <div>
    <v-container>
      <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
        Consulta de trazabilidad
      </div>

      <v-expansion-panels v-model="activePanels">
          <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
              <v-expansion-panel-header>
                  <span class="header">Filtros</span>
                  <template v-slot:actions>
                      <v-btn icon @click.stop="toogleFilters">
                          <v-icon>mdi-menu-down</v-icon>
                      </v-btn>
                  </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  <v-form :disabled="loading">
                      <v-row>
                        <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                          <DateFind class="mb-n3" label="Desde" v-model="filtros.desdeFecha" :clearable="true"></DateFind>
                        </v-col>

                        <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                          <DateFind class="mb-n3" label="Hasta" v-model="filtros.hastaFecha" :clearable="true"></DateFind>
                        </v-col>

                        <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                          <DateFind class="mb-n3" label="Desde caducidad" v-model="filtros.desdeCaducidad" :clearable="true"></DateFind>
                        </v-col>

                        <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                          <DateFind class="mb-n3" label="Hasta caducidad" v-model="filtros.hastaCaducidad" :clearable="true"></DateFind>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                          <ArtCatFind class="mb-n7" ref="artCatCtrl" label="Tipo" :artCatIdParent="1" v-model="filtros.artCat" :readonly="true" :selectOnReadonly="true"></ArtCatFind>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                          <ArtFind class="mb-n7" v-model="filtros.art"></ArtFind>
                        </v-col>

                        <v-col cols="6" sm="6" md="4" lg="2" xl="2">
                          <v-text-field 
                            class="mb-n7"          
                            v-model="filtros.lote"
                            outlined
                            label = "Lote"
                            dense
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <template v-if="$store.state.esDepartamento">
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                              <DirFind class="mb-n7" ref="dirCtrl" v-model="filtros.dir" label="Tienda"></DirFind>
                              <!-- <PerFind class="mb-n4" ref="paraUsuarioCtrl" v-show="esMensajeIndividual" label="Individual" :perCatId="tipoGrupo.PerCatId" tipoFiltro="MENSAJES_PERMITIDOS" :perIdRegistrado="$store.state.perId" v-model="paraUsuario" :readonly="enviado" multiple smallChips deletableChips></PerFind> -->
                            </v-col>
                        </v-row>
                      </template>

                      <v-row justify="center">
                        <v-col cols="4" md="3" lg="2" xl="2">
                          <v-btn
                            block color="primary"
                            @click="procTrazabilidades('HTML');"
                            :disabled="loading"
                            >
                              Ver
                          </v-btn>
                        </v-col>
                      </v-row>
                  </v-form>
              </v-expansion-panel-content>
          </v-expansion-panel>
      </v-expansion-panels>
      
      <v-row class="mt-2">
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 30, 50, 100], disablePagination:loading, disableItemsPerPage: loading}"
            :server-items-length="totalItems"
            :loading="loading"
            style="overflow: scroll"
            mobile-breakpoint="0"
            class="elevation-1">  
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Action="{ item }">
                    <v-btn icon :disabled="loading" @click="onClickDetailRow(item)">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="showDetailDialog"
      transition="dialog-bottom-transition"
      eager
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Detalle trazabilidad
      </v-card-title>
      <v-card-text class="mt-2">
          <ConsultaTrazbilidadDetalle v-show="selectedItem != null" ref="TrazDetalle" 
              :item.sync="selectedItem" 
              :filtros="filtrosCopy">
          </ConsultaTrazbilidadDetalle>
      </v-card-text>
      
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="onCloseDetailDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  import ArtCatFind from "../components/ArtCatFind.vue";
  import ArtFind from "../components/ArtFind.vue";
  import DirFind from "../components/DirFind.vue";
  import DateFind from "../components/DateFind.vue";
  import ConsultaTrazbilidadDetalle from "../components/ConsultaTrazbilidadDetalle.vue";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    components: {
      ArtFind, ArtCatFind, DirFind, DateFind, ConsultaTrazbilidadDetalle
    },
    data () {
      return {
        activePanels: 0,
        loading: false,
        showDetailDialog: false,
        filtros: {
          desdeFecha: null,
          hastaFecha: null,
          desdeCaducidad: null,
          hastaCaducidad: null,
          dir: {},
          artCat: {},
          art: {},
          lote: ''
        },
        filtrosCopy: {}, // copia de los filtros para pasarlos al detalle
        items: [],
        options: {itemsPerPage: 30},
        totalItems: 0,
        reportFileName: '',
        headers: [
          { text: '', value: 'Action', sortable: false, width: '1'},
          { text: 'CÓDIGO', value: 'Art.ArtCod', sortable: false, width: '80' },
          { text: 'ARTÍCULO', value: 'Art.ArtNom', sortable: false, width: '360' },
          // { text: 'FECHA', value: 'ArtTrazFecha', width: '100' },
          // { text: 'HORA', value: 'ArtTrazHora', width: '100' },
          { text: 'CANTIDAD', value: 'ArtTrazCantTotal', sortable: false, width: '100' },
          // { text: 'LOTE', value: 'ArtTrazLote', width: '120' },
          // { text: 'CADUCIDAD', value: 'ArtTrazCadFecha', width: '180' },
          // { text: 'RETIRADA', value: 'FechaRetirada', width: '180' }
        ],
        dtOptionsLoaded: false,
        selectedItem: null
      }
    },
    watch: {
        options: {
          handler () {
            // Evitamos la llamada a la API en la primera carga de las options
            if(!this.dtOptionsLoaded) {
              this.dtOptionsLoaded = true;
              return;
            }
            this.procTrazabilidades('HTML');
          },
          deep: true
        },
    },
    mounted() {
      if (this.$store.state.esDir) {
        this.filtros.dir = this.$store.state.dir;
      }
    },
    methods: {
      procTrazabilidades(Destino){
        if (this.loading) { return; }
        this.filtrosCopy = JSON.parse(JSON.stringify(this.filtros));
        this.procesarTrazabilidades(Destino)
          .then(data => {
            if (Destino == 'HTML')
            {
              this.items = data.items;
              this.totalItems = data.total;

              if(this.items.length === 0 && this.totalItems > 0)
              {
                  this.options.page = 1;
              }
            }
          });
      },
      procesarTrazabilidades(Destino){
        this.loading = true;
        return new Promise((resolve) => {
          let ActionVar;
          let NumRegsPag;
          let NumPag;
          if (Destino == 'HTML' || Destino == null || Destino == '')
          {
              ActionVar = 'GET_GROUPED_LIST_PAGINATED';
              NumRegsPag = this.options.itemsPerPage;
              NumPag = this.options.page;
          }

          const controllerParameters = {
              Action: ActionVar, 
              desdeFecha: this.filtros.desdeFecha !== '' ? this.filtros.desdeFecha : null,
              hastaFecha: this.filtros.hastaFecha !== '' ? this.filtros.hastaFecha : null,
              desdeCaducidad: this.filtros.desdeCaducidad !== '' ? this.filtros.desdeCaducidad : null,
              hastaCaducidad: this.filtros.hastaCaducidad !== '' ? this.filtros.hastaCaducidad : null,
              DirId: this.filtros.dir != null ? this.filtros.dir.DirId : null,
              // PerId: this.filtros.per.PerId,
              NumRegsPag: NumRegsPag,
              NumPag: NumPag,
              EmpId: this.empId,
              ArtCatId: this.filtros.artCat != null ? this.filtros.artCat.ArtCatId : null,
              ArtId: this.filtros.art != null ? this.filtros.art.ArtId : null,
              ArtTrazLote: this.filtros.lote,
              TieneCantidad: true,
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: 'POST', 'url': this.urlRaiz + '/api/ArtTraz', 'data': JSON.stringify(controllerParameters), 'headers': {'content-type': 'application/json', 'Authorization': AuthToken } })
          .then(result => {
              const items = result.data.EntsList;
              const total = result.data.EntsTotCount;

              setTimeout(() => {
                  this.loading = false
                  resolve({
                      items,
                      total
                  });
              }, 1000)          
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar los datos de trazabilidad.');
            }
          });
        });
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      onClickDetailRow(item) {
        this.$refs.TrazDetalle.loading = false;
        this.selectedItem = item;
        this.showDetailDialog = true;
      },
      onCloseDetailDialog() {
        this.showDetailDialog = false;
        this.selectedItem = null;
      }
  },
}
</script>
